<template>
  <div class="social-responsibility main-wrapper">
    <!-- <div class="first-title">社会责任</div> -->
    <img :src="image.url" />
  </div>
</template>

<script>
import { getCosUrl } from '../utils';
export default {
  name: "social-responsibility",
  data(){
    return {
        image: {
            key: "/social/1.webp",
            url: ""
        }
    }
  },
  watch: {
    "$store.state.device": {
        handler(newVal, oldVal){
            if(newVal != oldVal){
                this.image.url = getCosUrl(this.image.key);
            }
        },
        deep: true
    },
    "$store.state.language": {
        handler(newVal, oldVal){
            if(newVal != oldVal){
                this.image.url = getCosUrl(this.image.key);
            }
        },
        deep: true
    }
  },
  mounted() {
    this.image.url = getCosUrl(this.image.key);
  },
};
</script>

<style lang="less">
div.social-responsibility {
//   background: #f7f7f7;
//   justify-content: center;
//   flex-direction: column;
//   align-items: center;
//   position: relative;
//   display: flex;
//   img {
//     width: 100rem !important;
//     // margin-left: 23.9583%;
//     margin-bottom: 12rem;
//   }
}
</style>
